
function isValidEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

(function () {
  "use strict";

  // Start Syntax Highliter
  // check if element exists
  if (typeof hljs != "undefined") {
    hljs.initHighlighting();
  }

  // METHODS

  let getElement = function (el) {
    return document.querySelector(el);
  };

  let getAllElements = function (el) {
    return document.querySelectorAll(el);
  };

  let toggleBodyScroll = function () {
    getElement("body").classList.toggle("freeze");
  };

  let toggleable = function (el, className) {
    el.classList.toggle(className);
  };

  let addClass = function (el, className) {
    el.classList.add(className);
  };

  let removeClass = function (el, className) {
    el.classList.remove(className);
  };

  let hasClass = function (el, classe) {
    return el.classList.contains(classe);
  };

  let isObjectEmpty = function (obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  // Always escape HTML for text arguments!
  function escapeHtml(html) {
    const div = document.createElement('div');
    div.textContent = html;
    return div.innerHTML;
  }

  // Custom function to emit toast notifications
  function notify(message, variant = 'primary', icon = 'info-circle', duration = 4000) {
    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration: duration,
      innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `
    });

    document.body.append(alert);
    return alert.toast();
  }



  // Ajax object
  let ajax = null;
  if (window.XMLHttpRequest) {
    // code for modern browsers
    ajax = new XMLHttpRequest();
  } else {
    // code for old IE browsers
    ajax = new ActiveXObject("Microsoft.XMLHTTP");
  }

  const PREFIX = '/en';

  // END METHODS

  let content = getElement("#content");
  let shadow = getElement(".shadow");
  let tabs = Array.from(document.querySelectorAll("[data-tab-title]"));
  let tabsContent = Array.from(document.querySelectorAll("[data-tab-name]"));
  let megamenu = getElement(".dropdown");

  // TABS
  let activateTab = function (e, tab) {
    var currentTab = getElement(".is-active");
    var currentTabTitle = tab.getAttribute("data-tab-title");
    var currentActiveTabTitle = currentTab.getAttribute("data-tab-title");

    // Stop the process if the click on an activetab
    if (currentActiveTabTitle == currentTabTitle) {
      return;
    }

    // Go through the tabs and disable them
    tabs.forEach(function (tab) {
      tab.classList.remove("is-active");
    });
    // Go through the tabs content and disable them
    tabsContent.forEach(function (content) {
      content.classList.remove("show");
      var currentTabContentTitle = content.getAttribute("data-tab-name");

      // Activate currently clicked tab content
      if (currentTabContentTitle == currentTabTitle) {
        content.classList.add("show");
        return; // at the moment we find the right content we stop
      }
    });

    // Activate currently clicked tab
    tab.classList.add("is-active");
  };

  tabs.forEach(function (tab) {
    const attr = tab.getAttribute("data-tab-title");
    tab.innerText = attr.toUpperCase();
    tab.addEventListener("click", (e) => activateTab(e, tab));
  });

  

  // STICKY ELEMENTS
  const scrollY = function () {
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = (document.compactMode || "") === "CSS1Compat";
    return supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
  };

  var elements = document.querySelectorAll("[data-sticky]");
  for (var i = 0; i < elements.length; i++) {
    (function (element) {
      var elementCords = element.getBoundingClientRect();
      var offset = parseInt(element.getAttribute("data-offset") || 0, 10);
      var top = elementCords.top + scrollY();
      var width = elementCords.width;
      var height = elementCords.height;

      var fakeElement = document.createElement("div");
      fakeElement.style.height = height;

      var onScroll = function () {
        var hasScrollClass = element.classList.contains("fixed");
        // if ( scrollY() > top - offset && !hasScrollClass ) {
        if (scrollY() > offset && !hasScrollClass) {
          addClass(element, "fixed");
          // addClass(element, 'shadowed')
          element.parentNode.insertBefore(fakeElement, element);
          element.style.top = offset + "px";
        } else if (scrollY() < top - offset && hasScrollClass) {
          removeClass(element, "fixed");
        } else if (scrollY() == offset && hasScrollClass) {
          removeClass(element, "fixed");
          // removeClass(element, 'shadowed')
          element.parentNode.removeChild(fakeElement);
        }
      };
      window.addEventListener("scroll", onScroll);
    })(elements[i]);
  }


  let panel = getElement(".panele");

  function message(type, text) {
    panel.classList.remove("hide");
    panel.classList.add("notification");
    panel.classList.add("" + type + "");
    panel.innerHTML = text;
    setTimeout(function () {
      panel.classList.remove("notification");
      panel.classList.add("hide");
      panel.classList.remove("" + type + "");
      panel.innerHTML = "";
    }, 5000);
  }
  

  // Add user role
 document.addEventListener('DOMContentLoaded', function() {
  var roleSelect = document.getElementById('role');
  var permissionsContainer = document.getElementById('permissions-container');
  var userPermissions = []; // Array to store user's existing permissions

  // Retrieve the user's existing permissions
  var userPermissionsInput = document.getElementById('user-permissions');
  if (userPermissionsInput) {
    userPermissions = JSON.parse(userPermissionsInput.value);
    var userPermissions = userPermissions.map(function(permission) {
    return permission.id;
  });
  }

roleSelect.addEventListener('sl-change', function() {
     let allBtn = document.createElement('h3');
      let all = document.querySelector('#all');
      all.innerHTML = '';
      allBtn.style.cursor = 'pointer';
      allBtn.style.border = '2px solid tomato';
      allBtn.style.borderRadius = '7px';
      allBtn.style.padding = '7px';
      allBtn.style.textAlign = 'center';
      allBtn.appendChild(document.createTextNode('Select All'));
      all.appendChild(allBtn)

    let boxes = [];
    var roleId = roleSelect.value;
    if (roleId) {
      fetch('/settings/users/roles/'+roleId, {
        method: 'GET',
      })
      .then(function(response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error fetching permissions');
      })
      .then(function(data) {

       permissionsContainer.innerHTML = ''; // Clear previous permissions
      
      data.forEach(function(permission) {
        var label = document.createElement('label');
        var checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.name = 'permissions[]';
        checkbox.value = permission.id;
        // Put it in list
        boxes.push(checkbox)

        // Check the checkbox if the permission is in userPermissions
        if (userPermissions.includes(permission.id)) {
          checkbox.checked = true;
        }else{
          //console.log('NO')
        }


        label.appendChild(checkbox);
        label.appendChild(document.createTextNode(permission.name));
        permissionsContainer.appendChild(label);
      });
      }).catch(function(error) {
        console.error(error);
      });

      // listen to select all event
      allBtn.addEventListener('click', function(e){
        e.preventDefault();
        boxes.forEach(function(box){
          if (box.checked == false) {
            box.checked = true;
          }else{
            // box.checked = false;
          }
          
        })
      })

    } else {
      permissionsContainer.innerHTML = '';
    }
  });
});


// Jquery Dependency
$("input[data-type='currency']").on({
    keyup: function() {
      formatCurrency($(this));
    },
    blur: function() { 
      formatCurrency($(this), "blur");
    }
});


function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


function formatCurrency(input, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  
  // get input value
  var input_val = input.val();
  
  // don't validate empty input
  if (input_val === "") { return; }
  
  // original length
  var original_len = input_val.length;

  // initial caret position 
  var caret_pos = input.prop("selectionStart");
    
  // check for decimal
  if (input_val.indexOf(".") >= 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);
    
    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }
    
    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    // input_val = "¢" + left_side + "." + right_side;
    input_val =  left_side + "." + right_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    // input_val = "¢" + input_val;
    input_val = input_val;
    
    // final formatting
    if (blur === "blur") {
      input_val += ".00";
    }
  }
  
  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  var updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
}


// Activate all permissions
 const perm_container = document.querySelector("#permissions-container");
const checkboxes = Array.from(perm_container.querySelectorAll('sl-checkbox'));
const allBtn = document.querySelector('.check_all')
console.log(allBtn)
if ( allBtn != undefined ) {
  allBtn.addEventListener('click', function(e){
    checkboxes.forEach(function(ck){
      ck.checked = true
    });

  })
}

})();
